<template>
    <div>
        <!--公共头部-->
        <Header></Header>
        <!-- 用户中心头部 -->
        <user-header />
        <!-- 内容 -->
        <div class="main-content">
            <div class="container">
                <div class="accout-content clearfix"  style="display:flex">
                    <!-- 左边 -->
                    <user-left mark="rfq" :userId="userId" />
                    <!-- 右边 -->
                    <a-card :bordered="false" class="card-right" style="width:100%">
                         <div class="first">
                            <span><i style="color:red">*</i>{{ $t('user.rfq.title') }}:</span>
                            <input type="text" v-model="title">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.pay-type') }}:</span>
                            <input type="text" v-model="paymentType">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.delivery-place') }}:</span>
                            <input type="text" v-model="deliveryAddr">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.keywords') }}:</span>
                            <input type="text" v-model="productKeywords">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.name') }}:</span>
                            <input type="text" v-model="productName">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.category-one') }}:</span>
                            <a-select default-value="0" style="width: 310px" @change="handleChange">
                                <a-select-option value='0'>
                                    {{ $t('user.select-cate') }}
                                </a-select-option>
                                <a-select-option :value="item.categoryId" v-for="(item,index) in catelist1" :key="index">
                                    {{item.categoryName}}
                                </a-select-option>
                            </a-select>       
                        </div>
                        <div class="second" v-if="categoryId1!=0">
                            <span><i style="color:red">*</i>{{ $t('user.product.category-two') }}:</span>
                            <a-select default-value="" style="width: 310px" @change="handleChange2">
                               <a-select-option :value="item.categoryId" v-for="(item,index) in catelist2" :key="index">
                                    {{item.categoryName}}
                                </a-select-option>
                            </a-select>       
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.product.quantity') }}:</span>
                            <input type="text" v-model="productNum">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.budget') }}:</span>
                            <input type="text" v-model="budget">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.effective-date') }}:</span>
                            <a-date-picker @change="onChange" />
                        </div>
                         <div class="second">
                            <span style="float:left"><i style="color:red">*</i>{{ $t('user.attachment') }}:</span>
                            <a-upload
                                name='file'
                                list-type="picture-card"
                                :show-upload-list="false"
                                :customRequest="uploadImageFile"
                                :data="{ field: 'images' }"
                            >
                                <a-button> <a-icon type="upload" /> {{ $t('user.attachment-upload') }} </a-button>
                            </a-upload>
                            <span :v-if="annexes" v-for="(item,index) in annexes" :key="index">{{item.filename}}</span>
                        </div>
                        <button class="submit" @click="regsub()">{{ $t('user.submit') }}</button>
                    </a-card>
                </div>
            </div>
        </div>
        <!-- 公共尾部 -->
        <Footer></Footer>
    </div>
</template>

<style>
    .main-content .card-right{
        width: 100%;
    }
    .first,.second{
        text-align: left;
    }
    .first>span,.second>span{
        font-size: 16px;
        width: 140px;
        display: inline-block;
    }
    input[type=text]{
      width: 300px;
      border: 1px solid #787878;
      margin-left: 10px;
    }
    .second{
        margin-top: 20px;
    }
    .submit{
        width: 200px;
        height: 40px;
        margin-top: 40px;
        border: 1px solid  #d9d9d9;
        line-height: 40px;
        background: #ff4d4f;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
    }
    .ant-select-selection--single{
        margin-left: 10px;
        border: 1px solid black;
    }
    .ant-upload.ant-upload-select-picture-card{
        margin-left: 10px;
    }
    .ant-calendar-picker{
        margin-left: 10px;
    }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'ExbitAdd',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        userId: this.$route.params.userId,
        title: '',
        deliveryAddr: '',
        categoryId1: 0,
        categoryId2: '',
        paymentType: '',
        productKeywords: '',
        productName: '',
        productNum: '',
        budget: '',
        effectiveDate: '',
        thumb: '',
        annexes: [],
        filename: '',
        catelist1: [],
        catelist2: [],
    }
  },
   mounted () {
    this.getList()
  },
   methods: {
    onChange(date, dateString) {
      console.log(dateString);
      this.effectiveDate = dateString
    },
    handleChange(value) {
      this.categoryId1 = value
       this.$common.fetchList('/exhibition/category/lists?parentId='+this.categoryId1, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.catelist2 = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
    handleChange2(value) {
      this.categoryId2 = value
      console.log(this.categoryId2)
    },
    // 上传图片
    uploadImageFile (file) {
      console.log('file', file)
      const field = file.data.field
      this[field + 'UploadStatus'] = true // 按钮状态
      const formData = new FormData()
      formData.append('file', file.file)
      this.$common.uploadFile(formData).then(data => {
        console.log(data)
        if (data.code === 200) {
          const result = data.data
          if (field === 'images') {
              console.log(result)
            this.annexes.push({'url': result.url, 'filename': result.filename})
            console.log(this.annexes)
          } else {
            this.thumb = result.url
          }
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      }).finally(() => {
        this[field + 'UploadStatus'] = false
      })
    },
    // 获取行业列表
    getList () {
       this.$common.fetchList('/exhibition/category/lists?parentId='+this.categoryId1, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.catelist1 = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
    // 产品添加
    regsub() {
        // if (this.title == '' || this.deliveryAddr == '' || this.categoryId1 == '' || this.categoryId2 == '' || this.paymentType == '' 
        //     || this.productKeywords == '' || this.productName == '' || this.productNum == '' || this.budget == '' || this.annexes == '' 
        //     || this.effectiveDate == '') {
        //     this.$layer.alert("请正确填写信息");
        //     return 
        // }
        if (this.title == '') {
           this.$layer.alert("rfq标题不能为空");
          return 
        } else if (this.deliveryAddr == '') {
           this.$layer.alert("交货地点不能为空");
          return 
        } else if (this.categoryId1 == '') {
           this.$layer.alert("一级分类不能为空");
          return 
        } else if (this.categoryId2 == '') {
           this.$layer.alert("二级分类不能为空");
          return 
        } else if (this.paymentType == '') {
           this.$layer.alert("支付方式不能为空");
          return 
        } else if (this.productKeywords == '') {
           this.$layer.alert("产品关键字不能为空");
          return 
        } else if (this.productName == '') {
           this.$layer.alert("产品名称不能为空");
          return 
        } else if (this.productNum == '') {
           this.$layer.alert("产品数量不能为空");
          return 
        } else if (this.budget == '') {
           this.$layer.alert("预算不能为空");
          return 
        } else if (this.annexes == '') {
           this.$layer.alert("附件不能为空");
          return 
        } else if (this.effectiveDate == '') {
           this.$layer.alert("有效日期为空");
          return 
        }
        const params = {
            title: this.title,
            deliveryAddr: this.deliveryAddr,
            categoryId1: this.categoryId1,
            categoryId2: this.categoryId2,
            paymentType: this.paymentType,
            productKeywords: this.productKeywords,
            productName: this.productName,
            productNum: this.productNum,
            budget: this.budget,
            effectiveDate: this.effectiveDate,
            annexes: this.annexes
        }
        this.$common.handlePost('/user/rfq/add', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.$router.push({name: 'UsersRFQ', params: {userId: this.userId}})
        } else {
            this.$layer.alert(data.msg);
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>